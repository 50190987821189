<template>
    <svg width="1600px" height="900px" viewBox="0 0 1600 900" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        
        <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="50%" gradientTransform="translate(0.500000,0.500000),rotate(90.000000),scale(1.000000,0.980555),translate(-0.500000,-0.500000)" id="radialGradient-3">
            <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
            <stop stop-color="#FFFFFF" stop-opacity="0.466182255" offset="100%"></stop>
        </radialGradient>
        <path d="M14.399939,0 C9.09791629,0 4.80000019,4.20140023 4.80000019,9.38394479 C4.80000019,11.6930172 7.66412882,16.5600732 7.66412882,16.5600732 L14.399939,27.6000011 L20.7486549,16.5600732 C20.7486549,16.5600732 24.000001,11.6584597 24.000001,9.38394479 C24.000001,4.20140023 19.7019618,0 14.399939,0" id="path-4"></path>
        <filter x="-101.6%" y="-70.7%" width="303.1%" height="248.3%" filterUnits="objectBoundingBox" id="filter-5">
            <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Casinos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Wynn">
            <g id="path-wynn">
                <g id="Route" stroke-linecap="round" stroke-linejoin="round">
                    <filter id="glow-1" x="-2" y="-2" width="200" height="200">
                        <feGaussianBlur stdDeviation="4"/>
                    </filter>
                    <path filter="url(#glow-1)" stroke="#FFFFFF" stroke-width="6.00000024" d="M802.600005,267.200001 L818.499023,236.99707 C811.905924,230.472331 804.042643,227.680339 794.90918,228.621094 C781.208984,230.032227 748.188477,228.753906 742.789062,226.668945 L684.888672,336.439453 L668.347656,368.040039 L657.890625,376.384766 C655.414714,380.568359 653.574219,387.441081 652.369141,397.00293 L634.563477,438.618164 L634.563477,455.711914 C635.216471,463.338216 637.954427,468.991862 642.777344,472.672852 C650.011719,478.194336 656.603516,482.151367 656.603516,497.5 C656.603516,507.732422 656.603516,535.955729 656.603516,582.169922 C656.722656,590.961589 654.816732,597.342773 650.885742,601.313477 C644.989258,607.269531 637.499023,614.038086 637.499023,624.616211 C637.499023,631.668294 640.399089,637.499023 646.199219,642.108398 C653.135417,646.653876 656.603516,653.79092 656.603516,663.519531 C656.603516,673.248142 656.603516,695.241632 656.603516,729.5 L635,729.5" class="path-glow"></path>
                    <path stroke="#FFFFFF" stroke-width="6.00000024" d="M802.600005,267.200001 L818.499023,236.99707 C811.905924,230.472331 804.042643,227.680339 794.90918,228.621094 C781.208984,230.032227 748.188477,228.753906 742.789062,226.668945 L684.888672,336.439453 L668.347656,368.040039 L657.890625,376.384766 C655.414714,380.568359 653.574219,387.441081 652.369141,397.00293 L634.563477,438.618164 L634.563477,455.711914 C635.216471,463.338216 637.954427,468.991862 642.777344,472.672852 C650.011719,478.194336 656.603516,482.151367 656.603516,497.5 C656.603516,507.732422 656.603516,535.955729 656.603516,582.169922 C656.722656,590.961589 654.816732,597.342773 650.885742,601.313477 C644.989258,607.269531 637.499023,614.038086 637.499023,624.616211 C637.499023,631.668294 640.399089,637.499023 646.199219,642.108398 C653.135417,646.653876 656.603516,653.79092 656.603516,663.519531 C656.603516,673.248142 656.603516,695.241632 656.603516,729.5 L635,729.5" class="path-line"></path>
                </g>
                <g id="Ending-Point" transform="translate(619.800004, 715.000000)">
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="14.6000011" r="14.4000006"></circle>
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="14.6000011" r="9.60000038"></circle>
                    <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="14.6000011" r="4.80000019"></circle>
                </g>
                <image id="Bitmap" x="496" y="663" width="157" height="141" href="@/assets/images/icons/end.png"></image>
                <g id="Starting-Point" transform="translate(787.800004, 240.000000)">
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="27.6000011" r="14.4000006"></circle>
                    <circle id="Oval-back" fill="url(#radialGradient-3)" cx="14.4000006" cy="27.6000011" r="9.60000038"></circle>
                    <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="27.6000011" r="4.80000019"></circle>
                    <g id="Fill-1">
                        <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4"></use>
                        <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-4"></use>
                    </g>
                </g>
                <text id="Encore/-Wynn" font-family="sans-serif" font-size="14.4000006" font-weight="normal" line-spacing="15.6000006" fill="#FFFFFF">
                    <tspan x="830" y="272">Encore &amp; Wynn</tspan>
                </text>
                <rect id="Rectangle" fill="#000000" x="1599" y="899" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="0" y="0" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="1599" y="0" width="1" height="1"></rect>
                <rect id="Rectangle" fill="#000000" x="0" y="899" width="1" height="1"></rect>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
    name: "EncoreWynnPath",
    mounted() {
        var path = document.querySelector('.path-line')
        var length = path.getTotalLength()
        path.style.transition = path.style.WebkitTransition = 'none'
        path.style.strokeDasharray = length + ' ' + length
        path.style.strokeDashoffset = length
        path.getBoundingClientRect()
        path.style.transition = path.style.WebkitTransition =
        'stroke-dashoffset 2s ease-in-out'
        path.style.strokeDashoffset = '0'

        var pathGlow = document.querySelector('.path-glow')
        var lengthGlow = pathGlow.getTotalLength()
        pathGlow.style.transition = pathGlow.style.WebkitTransition = 'none'
        pathGlow.style.strokeDasharray = lengthGlow + ' ' + lengthGlow
        pathGlow.style.strokeDashoffset = lengthGlow
        pathGlow.getBoundingClientRect()
        pathGlow.style.transition = pathGlow.style.WebkitTransition =
        'stroke-dashoffset 2s ease-in-out'
        pathGlow.style.strokeDashoffset = '0'
    }
}
</script>

<style lang="scss" scoped>

</style>